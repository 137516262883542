import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×8\\@75%1RM`}</p>
    <p>{`Glute Ham Raise 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 each of:`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`Power Cleans (115/75)`}</p>
    <p>{`Wall Balls (20/14)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Granite Games Fall Throwdown is September 14th and registration
is now live!  Get your team of 3 together and register
at:`}</em></strong><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/2396#.XQlcOa2ZPys"
      }}>{`https://competitioncorner.net/events/2396#.XQlcOa2ZPys`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      